// src/components/ContactUs.js
import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import emailjs from "emailjs-com";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const theme = useTheme();
  const [selectedLocation, setSelectedLocation] = useState(
    "Sant Andreu de la Barca"
  ); // Nuevo estado para el lugar

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value); // Cambiar la ubicación seleccionada
  };
  const [submitMessage, setSubmitMessage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    var service = "service_9f6negx";
    var template = "template_nu9kh9h";
    if (selectedLocation === "Sant Andreu de la Barca") {
      var service = "service_9f6negx";
      var template = "template_nu9kh9h";
    } else {
      var service = "service_b5px67f";
      var template = "template_ys7basn";
    }

    emailjs.sendForm(service, template, e.target, "CV71sZubqZdpfnrKQ").then(
      (result) => {
        setSubmitMessage("El correo se ha enviado con éxito");
        setFormData({
          name: "",
          email: "",
          phone: "",
          description: "",
        });
      },
      (error) => {
        setSubmitMessage(
          "Hubo un error al enviar el correo. Por favor, inténtalo de nuevo."
        );

        console.log(error.text);
      }
    );
  };

  return (
    <Container
      id="contacto"
      maxWidth="lg"
      sx={{
        backgroundColor: theme.palette.background.primary,
        minWidth: "100%", // Establecer la altura mínima a 100% del viewport height
        padding: "2%",
        borderRadius: "5px",
      }}
    >
      <Typography variant="h2" align="center" color="white">
        Contacta con nosotros
      </Typography>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50px",
          width: "90%",
          margin: "0 auto",
          p: 4,
          backgroundColor: "white",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <Container sx={{ padding: "0, 100px" }}>
              <form onSubmit={handleSubmit}>
                <TextField
                  required
                  fullWidth
                  name="name"
                  label="Nombre"
                  value={formData.name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 500 }}
                  sx={{ borderRadius: "5px", mb: 2 }}
                />
                <TextField
                  fullWidth
                  name="email"
                  label="Correo electrónico"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  inputProps={{ maxLength: 150 }}
                  sx={{ borderRadius: "5px", mb: 2 }}
                />
                <TextField
                  fullWidth
                  name="phone"
                  label="Teléfono"
                  value={formData.phone}
                  onChange={handleChange}
                  inputProps={{ maxLength: 150 }}
                  sx={{ borderRadius: "5px", mb: 2 }}
                />
                {/* Desplegable para seleccionar ubicación */}
                <FormControl fullWidth sx={{ marginBottom: "16px" }}>
                  <InputLabel>Contactar con:</InputLabel>
                  <Select
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    label="Contactar con:"
                  >
                    <MenuItem value="Sant Andreu de la Barca">
                      Sant Andreu de la Barca
                    </MenuItem>
                    <MenuItem value="Martorell">Martorell</MenuItem>
                  </Select>
                </FormControl>
                <TextareaAutosize
                  required
                  fullWidth
                  name="description"
                  label="Descripción de la consulta"
                  value={formData.description}
                  onChange={handleChange}
                  inputProps={{ maxLength: 2000 }}
                  minRows={4}
                  maxRows={6}
                  placeholder="Descripción de la consulta"
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    marginBottom: "8px",
                    padding: "8px",
                  }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography variant="subtitle2">
                    {formData.description.length} de 2000 máximo de caracteres
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.tertiary.main,
                      "&:hover": {
                        backgroundColor: theme.palette.tertiary.main,
                        color: theme.palette.primary.main,
                      },
                      fontFamily: theme.typography.button.fontFamily,
                    }}
                  >
                    Enviar
                  </Button>
                </div>
              </form>
              {submitMessage && <div>{submitMessage}</div>}
            </Container>
          </Grid>
          <Grid item xs={12} sm={4}>
            {selectedLocation === "Sant Andreu de la Barca" ? (
              <>
                <div sx={{ mt: 2, borderRadius: "5px", overflow: "hidden" }}>
                  <iframe
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Carrer%20Anoia%2029%20-%2031,%20Sant%20Andreu%20de%20la%20Barca,%20Espa%C3%B1a+(MesVida)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    width="100%"
                    height="200"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
                <div>
                  <FontAwesomeIcon icon={faPhone} />
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{ ml: 1 }}
                  >
                    (+34) 692 87 16 59
                  </Typography>
                </div>
                <div>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{ ml: 1 }}
                  >
                    Carrer Anoia 29 - 31, Sant Andreu de la Barca, España
                  </Typography>
                </div>
                <div>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{ ml: 1 }}
                  >
                    info@mesvida.com
                  </Typography>
                </div>
              </>
            ) : (
              selectedLocation === "Martorell" && (
                <>
                  <div sx={{ mt: 2, borderRadius: "5px", overflow: "hidden" }}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2989.213499043572!2d1.915612976468261!3d41.47797089037099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a48f500ccb4263%3A0xc7407c3a931182dc!2sMESVIDA%20FITNESS!5e0!3m2!1ses!2ses!4v1732911935292!5m2!1ses!2ses" 
                      width="100%"
                      height="200"
                      frameborder="0"
                      style={{ border: 0 }}
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faPhone} />
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ ml: 1 }}
                    >
                      (+34) 692 87 16 59
                    </Typography>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ ml: 1 }}
                    >
                      Carrer de Pau Casals 45, Martorell, España
                    </Typography>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ ml: 1 }}
                    >
                      info.martorell@mesvida.com
                    </Typography>
                  </div>
                </>
              )
            )}
            {/*  <div >
              <FontAwesomeIcon icon={faPhone} />
              <Typography variant="subtitle1" component="span" sx={{ ml: 1 }}>
              (+34) 692 87 16 59
              </Typography>
            </div>
            <div>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <Typography variant="subtitle1" component="span" sx={{ ml: 1 }}>
              Carrer Anoia 29 - 31, Sant Andreu de la Barca, España
              </Typography>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} />
              <Typography variant="subtitle1" component="span" sx={{ ml: 1 }}>
                info@mesvida.com
              </Typography>
            </div>
            */}
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default ContactUs;
