import React from "react";
import "./GymLocations.css"; // Archivo de estilos
import fotoMartorell from "../../assets/Images/PontDelDiable_Martorell.jpg";
import fotoSantAndreu from "../../assets/Images/SantAndreuDeLaBarca.jpg";
import { Link } from "react-router-dom"; // Importar Link de React Router

const GymLocations = ({ bgColor }) => {
  const locations = [
    {
      city: "Sant Andreu de la Barca",
      imgSrc: fotoSantAndreu, // Cambia por la ruta correcta
      link: "/santAndreu", // Rutas internas de React Router
    },
    {
      city: "Martorell",
      imgSrc: fotoMartorell, // Cambia por la ruta correcta
      link: "/Martorell", // Rutas internas de React Router
    },
  ];

  return (
    <div className="gym-locations" style={{ backgroundColor: bgColor }}>
      <h1>¿Y dónde estamos?</h1>
      <div className="locations-container">
        {locations.map((location, index) => (
          <div className="location-card" key={index}>
            <div className="image-container">
              <img src={location.imgSrc} alt={location.city} />
              <Link to={location.link} className="view-more-button">
                Ver más
              </Link>
            </div>
            <h2>{location.city}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GymLocations;
