import React from 'react';
import { AppBar, Toolbar, Button, Typography, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, MenuItem, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/Images/Logo.png'; // Ajusta la ruta según la ubicación de tu archivo de logotipo
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import theme from '../../theme';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';



const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isTransparent, setIsTransparent] = React.useState(true);
  const [logoSize, setLogoSize] = React.useState('initial');
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [submenuOpen, setSubmenuOpen] = React.useState(false);
  const currentTheme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsTransparent(false);
      isMobile ? document.documentElement.style.setProperty('--logo-size-mobile', '50px') : document.documentElement.style.setProperty('--logo-size-web', '75px'); // Cambiamos el valor de la variable a 120px
    } else {
      setIsTransparent(true);
      isMobile ? document.documentElement.style.setProperty('--logo-size-mobile', '75px') : document.documentElement.style.setProperty('--logo-size-web', '100px');
    }
  };



  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const handleContactClick = () => {
    const contactSection = document.getElementById('contacto');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const handleServiceClick = () => {
    const serviciosSection = document.getElementById('servicios');
    if (serviciosSection) {
      serviciosSection.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/#servicios');
    }
  };


  const handleDrawerContactClick = () => {
    handleDrawerClose(); // Cierra el menú lateral
    setTimeout(() => {
    handleContactClick(); // Navega a la sección de contacto
  }, 200);
  };

  const handleSubmenuToggle = () => {
    setSubmenuOpen(!submenuOpen);
  };
  

  return (
    <AppBar position="fixed" style={{
      backgroundColor: isTransparent ? 'rgba(0, 0, 0, 0.2)' : currentTheme.palette.primary.main,
      transition: 'background-color 0.5s ease',
    }}>
      <Toolbar>
        <img src={logo} alt="Logotipo" className={`logo ${isMobile ? 'mobile' : 'web'}`} style={{ transform: `scale(${logoSize})` }} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
        {isMobile ? (
          <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
        ) : (
          <div className="nav-buttons">
            <Button color="inherit" component={Link} to="/" sx={navButtonStyles}>Inicio</Button>
            <Button color="inherit" aria-haspopup="true" onClick={handleServiceClick} onMouseEnter={handleClick}  sx={navButtonStyles}>Nuestros Gimnasios</Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onMouseLeave={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                sx:{
                backgroundColor: '#333',
                color: '#fff'
              },
              }}
            >
               {/*<MenuItem component={Link} to="/entrenamiento-personal"   onClick={handleClose}>Entrenamiento personal</MenuItem>
              <MenuItem component={Link} to="/gimnasio" onClick={handleClose}>Gimnasio</MenuItem>*/}
              <MenuItem component={Link} to="/santAndreu" onClick={handleClose}>Sant Andreu de la Barca</MenuItem>
              <MenuItem component={Link} to="/Martorell" onClick={handleClose}>Martorell</MenuItem>
            </Menu>
            
            <Button color="inherit" onClick={handleContactClick} sx={navButtonStyles}>Contacto</Button>
            {/*<Button color="inherit" component={Link} to="/blog" sx={navButtonStyles}>Blog</Button>*/}
          </div>
        )}
      </Toolbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(33, 33, 33, 0.9)', // Cambia el valor de la opacidad según desees
            width: '75%', // Cambia el ancho del Drawer según desees
          },
        }}
      >
<List>
    <ListItem button component={Link} to="/" onClick={handleDrawerClose} sx={drawerItemStyles}>Inicio</ListItem>
    <ListItem button onClick={handleSubmenuToggle} sx={drawerItemStyles}>
      {/*<ListItemText primary="Servicios" />*/}
      <ListItemText primary="Nuestros Gimnasios" />
      {submenuOpen ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={submenuOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {/*<ListItem button component={Link} to="/entrenamiento-personal" onClick={handleDrawerClose} sx={{ ...drawerItemStyles, pl: 4 }}>Entrenamiento personal</ListItem>
        <ListItem button component={Link} to="/gimnasio" onClick={handleDrawerClose} sx={{ ...drawerItemStyles, pl: 4 }}>Gimnasio</ListItem>*/}
        <ListItem button component={Link} to="/santAndreu" onClick={handleDrawerClose} sx={{ ...drawerItemStyles, pl: 4 }}>Sant Andreu de la Barca</ListItem>
        <ListItem button component={Link} to="/Martorell" onClick={handleDrawerClose} sx={{ ...drawerItemStyles, pl: 4 }}>Martorell</ListItem>
      </List>
    </Collapse>
    <ListItem button component={Link} onClick={handleDrawerContactClick} sx={drawerItemStyles}>Contacto</ListItem>
    {/*<ListItem button component={Link} to="/blog" onClick={handleDrawerClose} sx={drawerItemStyles}>Blog</ListItem>*/}
  </List>
      </Drawer>
    </AppBar>
  );
};

const navButtonStyles = {
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '2px',
    backgroundColor: 'currentColor',
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  '&:hover::after': {
    opacity: 1,
  },
  '&:hover': {
    color: theme.palette.secondary.main,
  },
};

const drawerItemStyles = {
  color: 'white',
};

export default Navbar;

