import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useTheme } from "@mui/system";

const ImageGallery = ({ title, images, columns, bckground }) => {
  const theme = useTheme();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image); // Guardar la imagen seleccionada en el estado
  };

  const handleClose = () => {
    setSelectedImage(null); // Cerrar el popup
  };

  return (
    <Box sx={{ backgroundColor: bckground, paddingBottom: "10px" }}>
      <Container maxWidth="md">
        <Typography variant="h2" textAlign="center" color="white" mb={5}>
          {title}
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {images.map((image, index) => (
            <Grid item xs={12 / columns} key={index}>
              <Box
                component="img"
                src={image.src}
                alt={image.alt}
                title={image.title}
                loading="lazy"
                width="100%"
                height="auto"
                sx={{
                  borderRadius: "10px",
                  cursor: "pointer", // Hacer que parezca clicable
                }}
                onClick={() => handleImageClick(image)}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Popup con la imagen ampliada */}
      <Dialog open={!!selectedImage} onClose={handleClose} maxWidth="lg">
        <DialogContent sx={{ p: 0, display: "flex", justifyContent: "center" }}>
          {selectedImage && (
            <Box
              component="img"
              src={selectedImage.src}
              alt={selectedImage.alt}
              width="100%"
              height="auto"
              sx={{
                maxHeight: "90vh", // Limitar el tamaño máximo
                maxWidth: "90vw",
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ImageGallery;
