// src/pages/EntrenamientoPersonal/EntrenamientoPersonal.js

import React, { useEffect } from 'react';
import BannerH1 from '../../Components/BannerH1/BannerH1';
import CenteredText from '../../Components/CenteredText/CenteredText';
import CardSection3 from '../../Components/CardSection3/CardSection3';
import ImageGallery from '../../Components/ImageGallery/ImageGallery';
import WaveSeparator from '../../Components/WaveSeparator/WaveSeparator';


import ReactGA from "react-ga";

import backgroundImg from '../../assets/FotosEntrenamientoPersonal/resized/DSC01538-38-min.jpg'; // Ajusta la ruta según la ubicación de tu imagen de fondo
import Img1 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01530-32.jpg'
import Img2 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01532-33.jpg'
import Img3 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01536-36-min.jpg'
import Img4 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01537-37-min.jpg'
import Img5 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01538-38-min.jpg'
import Img6 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01539-39-min.jpg'
import Img7 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01540-40-min.jpg'
import Img8 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01543-41-min.jpg'
import Img9 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01544-42-min.jpg'
import Img10 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01545-43-min.jpg'
import Img11 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01546-44-min.jpg'
import Img12 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01549-47-min.jpg'
import Img13 from '../../assets/FotosEntrenamientoPersonal/resized/DSC01550-48-min.jpg'


import ImgG1 from "../../assets/FotosGimnasio/resized/DSC01488-3-min.jpg";
import ImgG2 from "../../assets/FotosGimnasio/resized/DSC01489-4-min.jpg";
import ImgG3 from "../../assets/FotosGimnasio/resized/DSC01490-5-min.jpg";
import ImgG4 from "../../assets/FotosGimnasio/resized/DSC01492-6-min.jpg";
import ImgG5 from "../../assets/FotosGimnasio/resized/DSC01495-9-min.jpg";
import ImgG6 from "../../assets/FotosGimnasio/resized/DSC01497-11.jpg";
import ImgG7 from "../../assets/FotosGimnasio/resized/DSC01505-16.jpg";
import ImgG8 from "../../assets/FotosGimnasio/resized/DSC01507-17.jpg";
import ImgG9 from "../../assets/FotosGimnasio/resized/DSC01514-20.jpg";
import ImgG10 from "../../assets/FotosGimnasio/resized/DSC01523-27.jpg";
import ImgG11 from "../../assets/FotosGimnasio/resized/DSC01524-28.jpg";

import theme from '../../theme';




const imagesEntrenamientoPersonal = [
  
  {
    src:Img1,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img2,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img3,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img4,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img5,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img6,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img7,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img8,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img9,
     alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img10,
    alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img11,
    alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img12,
    alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
    {
    src:Img13,
    alt: 'Descripción de la imagen 1',
    title: 'Título de la imagen 1',
      },
  ];
  

  const imagesGimnasio = [
    {
      src: ImgG1,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG2,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG3,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG4,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG5,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG6,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG7,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG8,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG9,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG10,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },
    {
      src: ImgG11,
      alt: "Descripción de la imagen 1",
      title: "Título de la imagen 1",
    },]

const SantAndreu = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <BannerH1 height={800} title='MesVida Sant Andreu' backgroundImg={backgroundImg} />
      {/*<CenteredText
        title="¡Transforma tu vida con nuestro exclusivo servicio de Entrenamiento Personal!"
        text="Nuestro enfoque único en el acompañamiento diario garantiza que te mantengas motivado, enfocado y constantemente desafiado. Con el apoyo de nuestros entrenadores profesionales y altamente capacitados, no solo alcanzarás tus objetivos de acondicionamiento físico, sino que también aprenderás a adoptar un estilo de vida saludable y sostenible. ¡No esperes más! Da el primer paso hacia la mejor versión de ti mismo y descubre el verdadero poder de un entrenamiento personalizado y una atención incomparable. ¡Únete a nosotros y experimenta la diferencia de tener un compañero en cada paso de tu viaje de transformación!"
        backgroundColor={theme.palette.background.primary}
      />*/}
      <CenteredText
        title="¡Dos espacios en Sant Andreu de la Barca para revolucionar la ciudad!"
        text="
En Sant Andreu de la Barca te ofrecemos dos espacios totalmente independientes para que elijas cómo entrenar: uno dedicado al entrenamiento personal, donde contarás con la atención exclusiva de un entrenador en cada sesión para maximizar tus resultados, y otro, una sala separada con control de acceso y aforo limitado que podrás reservar cómodamente mediante nuestra app, ideal para entrenar a tu ritmo, sin agobios ni multitudes. ¡Elige tu espacio y entrena como siempre has querido!"
        backgroundColor={theme.palette.background.secondary}
      />

      <WaveSeparator fillColor={theme.palette.background.secondary} bgColor={theme.palette.background.primary} />



   

      <ImageGallery title={"Entrenamiento personal"} images={imagesEntrenamientoPersonal} columns={3} bckground={theme.palette.background.primary} />
      <WaveSeparator fillColor={theme.palette.background.primary} bgColor={theme.palette.background.secondary} />

      <ImageGallery title={"Gimnasio"} images={imagesGimnasio} columns={3} bckground={theme.palette.background.secondary} />

      <WaveSeparator fillColor={theme.palette.background.secondary} bgColor={theme.palette.background.primary} />



    </div>
  );
};

export default SantAndreu;
