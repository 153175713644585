import React from 'react';
import './NuestroValor.css';
import { FaBullseye, FaDumbbell, FaUsers, FaClock, FaAppleAlt } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const NuestroValor = ({ bgColor }) => {
  const theme = useTheme();

  const benefits = [
    { title: "Programas personalizados", icon: <FaBullseye size={60} color="#eb3646" /> },
    { title: "Equipamiento de calidad", icon: <FaDumbbell size={60} color="#eb3646" /> },
    { title: "Comunidad viva y activa", icon: <FaUsers size={60} color="#eb3646" /> },
    { title: "Flexibilidad horaria", icon: <FaClock size={60} color="#eb3646" /> },
    { title: "Ayuda nutricional", icon: <FaAppleAlt size={60} color="#eb3646" /> },
  ];

  return (
    <Container
    style={{ backgroundColor: bgColor }}
      maxWidth="lg"
      sx={{
        backgroundColor: theme.palette.background.primary,
        minWidth: '100%',
        padding: '2%',
        textAlign: 'center',
      }}
    >
      <Typography variant="h2" align="center" sx={{ color: 'white', marginBottom: '2%' }}>
        ¿Qué aporta MesVida a tu vida?
      </Typography>
      <Grid container spacing={1} justifyContent="center" sx={{ paddingX: '5%' }}>
        {/* Primera fila: 3 iconos */}
        <Grid container item xs={12} justifyContent="center" spacing={2}>
          {benefits.slice(0, 3).map((benefit, index) => (
            <Grid item xs={12} sm={4} sx={{ paddingX: 1 }} key={index}>
              <div className="item">
                <div className="icon-container">{benefit.icon}</div>
                <Typography variant="subtitle1" align="center" color="white">
                  {benefit.title}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        {/* Segunda fila: 2 iconos */}
        <Grid container item xs={12} justifyContent="center" spacing={2}>
          {benefits.slice(3).map((benefit, index) => (
            <Grid item xs={12} sm={5} sx={{ paddingX: 1 }} key={index}>
              <div className="item">
                <div className="icon-container">{benefit.icon}</div>
                <Typography variant="subtitle1" align="center" color="white">
                  {benefit.title}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default NuestroValor;
