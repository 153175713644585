import React, { useEffect } from "react";
import BannerInicio from "../../Components/BannerInicio/BannerInicio";
import H2_Imagen from "../../Components/H2_Imagen/H2_Imagen";
import Servicios from "../../Components/Servicios/Servicios";
import ValueSectionComponent from "../../Components/NuestroValor/NuestroValor";
import Testimonials from "../../Components/Testimonials/Testimonials";
import comoFuncionamosImage from "../../assets/Images/como-funcionamos.png"; // Importa tu imagen aquí
import theme from "../../theme";
import "./index.css";
import WaveSeparator from "../../Components/WaveSeparator/WaveSeparator";
import ReactGA from "react-ga";

import backgroundImg from "../../assets/Images/background.jpg"; // Ajusta la ruta según la ubicación de tu imagen de fondo
import PathGuide from "../../Components/PathGuide/PathGuide";
import GymLocations from "../../Components/GymLocations/GymLocations";


import imgCardPerdidaPeso from '../../assets/FotosEntrenamientoPersonal/cards/PerdidaPeso.jpg'
import imgCardPruebasFisicas from '../../assets/FotosEntrenamientoPersonal/cards/PruebasFisicas.jpg'
import RecuperacionLesiones from '../../assets/FotosEntrenamientoPersonal/cards/RecuperacionLesiones.jpg'
import imgCardapp from "../../assets/FotosGimnasio/cards/app.jpg";
import imgLlaveAcceso from "../../assets/FotosGimnasio/cards/llaveAcceso.jpg";
import imgCardLimitacionAforo from "../../assets/FotosGimnasio/cards/LimitacionAforo.jpg";
import CardSection3 from '../../Components/CardSection3/CardSection3';


const Inicio = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);


  
  const FacilidadesCards = [
    {
      title: 'Perdida de peso',
      description:
        '¡Adelgaza con éxito en nuestro gimnasio! Nuestros expertos te guiarán en el camino hacia tus metas de pérdida de peso. ',
      image: imgCardPerdidaPeso,
      backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
      textColor: theme.palette.card.textColor // o cualquier otro color válido en el tema de Material-UI
    },
    {
      title: 'Recuperación',
      description:
        'Contamos con profesionales especializados para guiarte en tu rehabilitación. ¡Vuelve a sentirte fuerte! ',
      image: RecuperacionLesiones,
      backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
      textColor: theme.palette.card.textColor // o cualquier otro color válido en el tema de Material-UI
    },
    {
      title: 'Preparación física',
      description:
        'Prepárate para pruebas deportivas con nosotros. Optimiza tu rendimiento y alcanza tus objetivos con entrenamientos personalizados.',
      image: imgCardPruebasFisicas,
      backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
      textColor: theme.palette.card.textColor // o cualquier otro color válido en el tema de Material-UI
    },
    {
      title: "APP para reservas",
      description: "Simplificamos la reserva y seguimiento de tus sesiones de entrenamiento. Asegura tu lugar en el gimnasio de manera conveniente con nuestra aplicación.",
      image: imgCardapp,
      backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
      textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
    },
    {
      title: "Acceso biométrico",
      description: 'Accede de manera segura y sin complicaciones a nuestras instalaciones con nuestro llavero de acceso',
      image: imgLlaveAcceso,
      backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
      textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
    },
    {
      title: "AFORO LIMITADO",
      description:
        "Disfruta de un ambiente exclusivo y seguro en nuestro gimnasio. Olvídate de las multitudes y aprovecha al máximo tu experiencia de entrenamiento.",
      image: imgCardLimitacionAforo,
      backgroundColor: theme.palette.card.background, // o cualquier otro color válido en el tema de Material-UI
      textColor: theme.palette.card.textColor, // o cualquier otro color válido en el tema de Material-UI
    },
  ];
  

  return (
    <>
      <BannerInicio height={800} backgroundImg={backgroundImg} />

      <PathGuide bgColor={theme.palette.background.secondary} />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
      {/*<H2_Imagen
        title="¿Cómo funcionamos?"
        image={comoFuncionamosImage}
        bgColor={theme.palette.background.primary}
      />
      
       <Servicios />
       
       
          <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />


       */}

      <GymLocations bgColor={theme.palette.background.primary} />
      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />

      <ValueSectionComponent bgColor={theme.palette.background.secondary} />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />

      {/*Card section de nuestros clientes suelen buscar*/}
      <CardSection3 title='Nuestros clientes suelen buscar' cardsInfo={FacilidadesCards} bckgrColor={theme.palette.background.primary} />
      <WaveSeparator
        fillColor={theme.palette.background.primary}
        bgColor={theme.palette.background.secondary}
      />
      <Testimonials bgColor={theme.palette.background.secondary}  />
      <WaveSeparator
        fillColor={theme.palette.background.secondary}
        bgColor={theme.palette.background.primary}
      />
    </>
  );
};

export default Inicio;
