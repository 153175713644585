import React from "react";
import "./UnderConstruction.css"; // Archivo CSS para estilos
import { FaHardHat } from "react-icons/fa"; // Ejemplo de ícono

import { FaDumbbell } from "react-icons/fa"; // Icono de mancuerna

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <div className="icon-container">
        <FaHardHat className="icon" />
      </div>
      <h1>¡Estamos trabajando en ello!</h1>
      <p>Esta página está en construcción. Vuelve pronto para más novedades.</p>
      <div className="animation">
      <FaDumbbell className="icon2"  />

      </div>
    </div>
  );
};

export default UnderConstruction;
