import React, { useState } from "react";
import { FaCommentAlt, FaDumbbell, FaMapMarkerAlt, FaTrophy } from "react-icons/fa";
import "./PathGuide.css";

const PathGuide = ({ bgColor }) => {
  const steps = [
    {
      title: "Habla con nosotros",
      description:
        "Nosotros te ayudaremos a determinar cuál sería el inicio de tu camino a tu nueva vida Fitness.",
      icon: <FaCommentAlt size={60} />,
    },
    {
      title: "Decide qué tipo de actividad necesitas",
      description:
        "Con nuestro apoyo, podrás decidir si necesitas un camino más guiado con nuestro entrenamiento personal o a tu ritmo mediante el uso de nuestras instalaciones.",
      icon: <FaDumbbell size={60} />,
    },
    {
      title: "Escoge tu lugar",
      description:
        "MesVida dispone de 2 espacios totalmente equipados para ti. Sant Andreu de la Barca y Martorell ya se han unido a la revolución del Fitness.",
      icon: <FaMapMarkerAlt size={60} />,
    },
    {
      title: "Disfruta de los resultados y de nuestra comunidad",
      description:
        "No hay nada mejor que ver tus avances, pero no olvides disfrutar del paso a paso acompañado de nuestro equipo y de la comunidad MesVida.",
      icon: <FaTrophy size={60} />,
    },
  ];

  const [currentStep, setCurrentStep] = useState(null);

  return (
    <div className="path-guide-container"
    style={{ backgroundColor: bgColor }}>
      <h1>Crea tu camino con nosotros</h1>
      <div className="steps-container">
        {steps.map((step, index) => {
          const isLeft = index % 2 === 0;
          return (
            <div
              key={index}
              className={`step ${isLeft ? "left" : "right"} ${
                currentStep === index ? "active" : ""
              }`}
              onMouseEnter={() => setCurrentStep(index)} // Activar el paso al pasar el ratón
              onMouseLeave={() => setCurrentStep(null)} // Desactivar al quitar el ratón
            >
              <div className="step-content">
                <div
                  className={`step-icon ${
                    currentStep === index ? "active" : ""
                  } ${isLeft ? "step-icon-left" : "step-icon-right"}`}
                >
                  {step.icon}
                </div>
                <div className="step-text">
                  <h2>{step.title}</h2>
                  <div className="step-description">{step.description}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PathGuide;
