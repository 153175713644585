import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Comments from '../../Components/Comments/Comments';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './Testimonials.css';
import { useTheme } from '@mui/material/styles';




const Testimonials = ({ bgColor }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();

  const comments = [
    {
      text: 'Me encanta entrenar en este gimnasio, es un ambiente muy agradable y el personal es muy profesional. Desde que me uní, he visto un gran progreso en mi condición física y estoy muy contento con los resultados. Recomiendo encarecidamente este gimnasio.',
      name: 'María García',
      image: 'avatar1.jpg',
    },
    {
      text: 'Este es el mejor gimnasio en el que he estado. Tiene una gran variedad de equipos de alta calidad y el personal es muy servicial y amable. Me encanta la atmósfera aquí, es muy motivador. Definitivamente seguiré siendo un miembro fiel.',
      name: 'Carlos Rodríguez',
      image: 'avatar2.jpg',
    },
    {
      text: 'El gimnasio es simplemente genial. Ofrece una amplia variedad de clases y programas, lo que me ha ayudado a mantenerme motivado y comprometido con mi entrenamiento. El personal es muy amable y siempre están dispuestos a ayudar.',
      name: 'Ana López',
      image: 'avatar3.jpg',
    },
  ];


  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % comments.length);
    }, 10000); // Ajusta el tiempo para cambiar de comentario (en milisegundos)

    return () => clearTimeout(timer);
  }, [activeIndex]);

  
return (
  <Container
    maxWidth={false}
    minHeight= '1000px'
    style={{ backgroundColor: bgColor }}
    sx={{
      backgroundColor: theme.palette.background.secondary,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px'
    }}
  >
    <Typography variant="h2" sx={{ color: 'white' }}>
      La experiencia de nuestros clientes
    </Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <ChevronLeftIcon
        sx={{ fontSize: 40, color: 'white', cursor: 'pointer' }}
        onClick={() =>
          setActiveIndex((prevIndex) => (prevIndex - 1 + comments.length) % comments.length)
        }
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <TransitionGroup component={null}>
          <CSSTransition key={activeIndex} classNames="slide" timeout={1000}>
          <Box
           sx={{
           
            width: '75%'
          }}>
            <Comments comment={comments[activeIndex]} />
                  </Box>

          </CSSTransition>
        </TransitionGroup>
      </Box>
      <ChevronRightIcon
        sx={{ fontSize: 40, color: 'white', cursor: 'pointer' }}
        onClick={() => setActiveIndex((prevIndex) => (prevIndex + 1) % comments.length)}
      />
    </Box>
  </Container>
);
};

export default Testimonials;

